<template>
    <div>
        <v-alert v-if="isResultPdfGenerated" min-height=200>
            <p class="text-h5 font-weight-bold teal--text text--darken-1 text-center">
                集計PDFのご用意ができました。
            </p>
        </v-alert>
        <v-alert v-else>
            <p class="text-h5 font-weight-bold teal--text text--darken-1 text-center">
                集計PDFを生成しています..
            </p>
            <p class="text-h5 teal--text text--darken-1 d-block text-center">
                (計算量によっては時間がかかります)<br/>
                途中でタブを閉じないで下さい。
            </p>
        </v-alert>
        <v-row>
            <v-col>
                <v-row justify="center">
                    <v-progress-circular
                        :rotate="180"
                        :size="125"
                        :width="15"
                        :value="progress"
                        :color="isResultPdfGenerated ? '#118C66' : '#C8E6C9'"
                    >
                        <span class="teal--text">{{ progress }}%</span>
                    </v-progress-circular>
                </v-row>
                <v-row justify="center" class="pt-5">
                    <v-btn
                    @click="downloadPdf"
                    rounded-lg
                    :color="isResultPdfGenerated ? '#118C66' : '#A0A0A0'"
                    :disabled="!isResultPdfGenerated"
                    width="300" height="50"
                    class="white--text font-weight-bold text-h6"
                >
                        集計PDFダウンロード
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="pl-5 pr-5">
            <v-btn
            to="total-result"
            rounded-lg color="#A0A0A0"
            width="150"
            height="60"
            class="mt-15 white--text font-weight-bold text-h6"
            >
                <v-icon large>mdi-chevron-left</v-icon>
                <v-spacer></v-spacer>
                戻る
                <v-spacer></v-spacer>
            </v-btn>
        </v-row>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex'

export default {
    data() {
        return {
            progress: 0,
            interval: {}
        }
    },
    mounted () {
        this.update({
            resultPdf: null,
            isResultPdfGenerated: false
        })

        // 集計pdfデータを取得する
        this.generatePdf()
    },
    computed: {
        ...mapGetters('result', {
            resultPdf: 'getResultPdf',
            isResultPdfGenerated: 'getIsResultPdfGenerated'
        }),
    },
    methods: {
        ...mapActions('result', [
            'update',
            'generateResultPdf'
        ]),
        generatePdf() {
            // 集計pdfデータを取得する
            this.generateResultPdf()
            
            // pdf生成のprogressを表示する
            this.interval = setInterval(() => {
                if (this.progress === 100) {
                    this.update({isResultPdfGenerated: true})
                    clearInterval(this.interval)
                    return this.progress
                }
                if (this.resultPdf) this.progress = 100
            }, 1000)
        },
        // 集計pdfをダウンロードする
        downloadPdf() {
            const blob = new Blob([this.resultPdf] , { type: 'application/pdf' })
            const filename = 'result.pdf'

            //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, filename)
            //その他ブラウザ
            } else {
                const fileURL = window.URL.createObjectURL(blob)
                let fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute('download', filename)
                document.body.appendChild(fileLink)

                fileLink.click()
            }
        }
    }
}
</script>